// 色系
$letter_primary: #FFF;
$letter_secondary: #999;
$red: #791718;
$red_light: #bd1c1c;
$red_extralight: #dd1111;
$red_error: #ff2e2e;
$bg_grad: radial-gradient(ellipse, #292929 0%,#202020 20%, #070707 100%);
$bg_primary: #111111;

$gray: #ececec;
$darkgray: #C6C6C6;
$selection_bg: $red;
$selection_text_color: #fff;
$selection_img_bg: #ffffff40;
$tw_blue: #1da1f2;

$border_primary: 1px solid $letter_primary;

// フォント系
$noto_yakuhan: "YakuHanJP", "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$noto: "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //light: 300, regular: 400, medium: 500, bold: 700
$josefin: 'Josefin Sans', "Noto Sans JP", 'Helvetica', 'arial', sans-serif; //extralight: 200 semibold: 600
$fw_exlight_200: 200;
$fw_light_300: 300;
$fw_regular_400: 400;
$fw_medium_500: 500;
$fw_semibold_600: 600;
$fw_bold_700: 700;

//ヘッダーフッターの高さ
$header_height: 150px;
$header_height_sp: clamp(40px, 10vh, 80px);

// 縦横比を固定する場合に使用
$ratio_16_9: 56.25%;
$ratio_4_3: 75%;
$ratio_3_2: 66.67%;
$ratio_1_1: 100%;

// メディアクエリー用
$pc_min: 1640px; // pcコンテンツ幅
$tab_max: 920px;
$sp_max: 712px;
$layout_min: 320px;
$content_pd_pc: 140px;
$body_pd: clamp(30px, 6vw, 60px);
$content_width_m: 1400px;
$content_width_s: 1100px;

// vw変換用(デザインデータキャンバス幅pxに合わせる)
$vp_basis_pc: 1920;
$vp_basis_sp: 375;

/*=======================================
  z-index管理
=========================================

---- 99999 ----


---- 1000 -----


---- 900 -----


----- 20 -----
#header_toggle


----- 15 -----
#sp_menu

----- 12 -----


----- 11 -----
.header_l

----- 10 -----
#header
#sp_menu

----- 9 -----
#pagetop_button

----- 8 -----
#footer

----- 7 -----


------ 5 ------


------ 2 ------


------ 1 ------


------ -1 ------
#first_view


------ -10 -----


------ -50 -----

------ -100 ----


------ -1000 ----


*/