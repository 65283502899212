@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*==============================
文字種
==============================*/
.c__common_text {
  font-size: clamp(14px, 2vw, 18px);
  color: v.$letter_primary;
  letter-spacing: 0.05em;
  line-height: 2;
  text-align: justify;
}
.c__error_message {
  color: v.$red_error;
  font-weight: v.$fw_medium_500;
  font-size: f.clamp_prf(14px, 18px);
  line-height: 1.5;
}
.c__text_underline {
  position: relative;
  width: 100%;
  padding-bottom: 0.15em;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #bbb;
  }
}

// 改行しても背景がぶつ切りにならないやつ
.c__box_decoration_break_clone {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  box-shadow:
    8px 8px 10px 0 #ff1492,
    -5px -5px 5px 0 #00f,
    5px 5px 15px 0 #ff0;
}

/*==============================
見出し系
==============================*/
.c__head_primary {
  display: block;
  width: fit-content;
  font-size: clamp(24px, 12vw, 160px);
  font-weight: v.$fw_bold_700;
  font-family: v.$josefin;
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(60);
  position: relative;
  .text_ja {
    position: absolute;
    top: 4.2em;
    left: 100%;
    z-index: 1;
    font-size: 0.15em;
    letter-spacing: 0.2em;
    white-space: nowrap;
  }
  .bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-57%, -47%) scale(0);
    opacity: 0;
    z-index: -1;
    width: 180%;
    height: 120%;
    transition: 0.35s ease;
    transition-delay: 0.2s;
    &.active {
      opacity: 1;
      transform: translate(-57%, -47%) scale(1);
      transform-origin: left bottom;
    }
  }
  &.small {
    font-size: clamp(40px, 12vw, 80px);
    .text_ja {
      top: 3em;
      left: 106%;
      font-size: 0.2em;
    }
    .bg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-57%, -47%);
      z-index: -1;
      width: 170%;
      height: 110%;
      transform: translate(-51%, -54%) scale(0);
      transition: 0.35s ease;
      transition-delay: 0.2s;
      opacity: 0;
      &.active {
        opacity: 1;
        transform: translate(-51%, -47%) scale(1);
        transform-origin: left bottom;
      }
    }
  }
}
.c__head_secondary {
  display: block;
  width: fit-content;
  font-size: f.clamp_prf(40px, 80px);
  font-weight: v.$fw_bold_700;
  font-family: v.$josefin;
  color: v.$letter_primary;
  letter-spacing: f.xd_letter_spacing(60);
  position: relative;
  .text_ja {
    position: absolute;
    top: 2.2em;
    left: 102%;
    z-index: 1;
    font-size: 0.25em;
    letter-spacing: 0.2em;
    white-space: nowrap;
  }
  .bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -59%) scale(0);
    z-index: -1;
    width: 150%;
    height: 80%;
    opacity: 0;
    transform-origin: left bottom;
    &.active {
      opacity: 1;
      transition: transform 0.35s ease;
      transition-delay: 0.2s;
      transform: translate(-48%, -59%) scale(1);
    }
  }
}
/*==============================
ボタン系
==============================*/
.c__primary_button {
  width: 100%;
  height: clamp(64px, 14vw, 130px);
  border: 2px solid #791718;
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: f.clamp_auto_pc2sp(44px, 0.5);
    width: 100%;
    height: 100%;
    position: relative;
    font-size: clamp(18px, 5vw, 45px);
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #791718;
      transform: scale(0, 1);
      transform-origin: right top;
      transition: transform 0.25s ease-in-out;
    }
    .link_name {
      font-size: 1em;
      font-family: v.$josefin;
      font-weight: v.$fw_semibold_600;
      letter-spacing: f.xd_letter_spacing(60);
      margin-bottom: -0.2em;
      z-index: 1;
    }
    .arrow {
      width: 0.8em;
      height: auto;
      z-index: 1;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
    }
    @include m.hover_device {
      &:hover {
        &:before {
          transform: scale(1, 1);
          transform-origin: left top;
        }
        .arrow {
          img {
            transition: transform 0.2s;
            transform: translateX(0.8em);
          }
        }
      }
    }
  }
}
.c__secondary_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: f.clamp_prf(64px, 80px);
  border: 2px solid v.$red;
  font-size: f.clamp_prf(16px, 20px);
  font-weight: v.$fw_semibold_600;
  letter-spacing: f.xd_letter_spacing(60);
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: v.$red;
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform 0.15s ease-in-out;
    z-index: -1;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-image: url(/assets/img/icon//wildcard_arrow_wh.svg);
    background-repeat: no-repeat;
    width: clamp(16px, 1.3em, 24px);
    height: clamp(16px, 1.3em, 24px);
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-46%);
    transition: 0.15s;
  }
  @include m.hover_device {
    &:hover {
      &:before {
        transform: scale(1, 1);
        transform-origin: left top;
      }
      .arrow {
        img {
          transition: transform 0.2s;
          transform: translateX(0.8em);
        }
      }
    }
  }
}
.c__button_underline_hover {
  display: inline-block;
  white-space: nowrap;
  font-family: v.$josefin;
  font-weight: v.$fw_medium_500;
  font-size: f.clamp_prf(16px, 20px);
  position: relative;
  letter-spacing: 0.05em;
  &:before {
    content: "";
    position: absolute;
    bottom: -0.2em;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: v.$letter_primary;
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform 0.25s ease-in-out;
  }
  @include m.hover_device {
    &:hover {
      &:before {
        transform: scale(1, 1);
        transform-origin: left top;
      }
    }
  }
}
.c__sns_service_icon {
  display: flex;
  gap: clamp(32px, 3.5vw, 40px);
  align-items: center;
  .service_icon {
    position: relative;
    display: block;
    height: 40px;
  }
  img {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: 0.15s;
  }
  .white {
    visibility: visible;
    opacity: 1;
  }
  .color {
    z-index: 10;
    visibility: hidden;
    opacity: 0;
  }
  .yt {
    width: f.clamp_prf(33px, 40px);
  }
  .tw {
    width: f.clamp_prf(30px, 37px);
  }
  .tk {
    width: f.clamp_prf(28px, 35px);
    margin-left: clamp(-8px, -0.5vw, -6px);
  }
  @include m.hover_device {
    .yt:hover,
    .tw:hover,
    .tk:hover {
      .color {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.c__sns_share {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 240px;
  height: 60px;
  font-size: clamp(18px, 3vw, 18px);
  font-family: v.$josefin; //明示
  font-weight: v.$fw_exlight_200;
  text-decoration: none;
  color: v.$letter_primary;
  background: transparent;
  border: solid 1px v.$red;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: v.$red;
    transform: scale(0, 1);
    transform-origin: right top;
    transition: transform 0.25s ease-in-out;
  }
  @include m.hover_device {
    &:hover {
      &:before {
        transform: scale(1, 1);
        transform-origin: left top;
      }
    }
  }
  .sns_share_text {
    position: relative;
    padding-left: clamp(2em, 2vw, 2.5em);
    margin-top: 2%;
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-image: url(/assets/img/icon/web_services/x_wh.svg);
      background-repeat: no-repeat;
      width: 1.3em;
      height: 1.3em;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background-size: 1em;
    }
  }
  @include m.sp {
    width: 100%;
  }
}
/*==============================
一覧系
==============================*/
.c__articles_grid {
  display: grid;
  gap: f.clamp_prf(36px, 60px) f.clamp_prf(20px, 50px);
  grid-auto-rows: minmax(100px, auto);
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  .anchor {
    .img_wrapper {
      display: block;
      position: relative;
      overflow: hidden;
      pointer-events: none;
      &:before {
        content: "";
        display: block;
        padding-top: v.$ratio_16_9;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.2s ease-out;
      }
    }
    .title {
      font-size: clamp(16px, 2vw, 20px);
      font-weight: v.$fw_semibold_600;
      margin-top: f.clamp_prf(16px, 22px);
      text-align: left;
      @include m.line_clamp(2);
      @include m.xd_cropped_line_height(20, 30);
      min-height: 24px;
      letter-spacing: f.xd_letter_spacing(140);
      pointer-events: none;
    }
    &:hover {
      img {
        transform: scale(105%);
      }
    }
    &.active {
      .img_wrapper {
        transform: scale(120%);
        transition: 0.6s;
        img {
          opacity: 0;
          filter: blur(10px);
          transition: 0.6s;
        }
      }
    }
  }
  .category {
    font-size: f.clamp_prf(12px, 16px);
    font-weight: v.$fw_exlight_200;
    margin-top: f.clamp_prf(6px, 10px);
    text-align: left;
    letter-spacing: 0.12em;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  @include m.tab {
    grid-template-columns: repeat(auto-fill, minmax(298px, 1fr));
  }
  @include m.sp {
    grid-template-columns: repeat(auto-fill, minmax(202px, 1fr));
  }
}
.c__categories_list {
  display: flex;
  flex-wrap: wrap;
  gap: f.clamp_prf(30px, 40px);
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-family: v.$noto;
  font-size: f.clamp_prf(12px, 18px);
  font-weight: v.$fw_semibold_600;
  letter-spacing: 0.12em;
  @include m.sp {
    gap: 18px clamp(16px, 3vw, 28px);
  }
  li {
    display: inline-block;
    .category_buttons {
      position: relative;
      padding-bottom: 12px;
      @include m.sp {
        padding-bottom: 6px;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 4px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform 0.15s;
      }
      &.active {
        &:before {
          transform: scale(1, 1);
          background-color: #fff;
        }
      }
      @include m.hover_device {
        &:hover {
          &:before {
            transform: scale(1, 1);
            background-color: #fff;
            transform-origin: left top;
          }
        }
      }
    }
  }
}
.c___news_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // 最初の要素では余白をなくす
  .news_item {
    display: flex;
    gap: f.clamp_prf(30px, 40px);
    padding: f.clamp_prf(20px, 32px) 0;
    align-items: center;
    width: 100%;
    position: relative;
    &:first-child {
      border-top: solid 1px #aaa;
    }
    @include m.sp {
      flex-direction: column;
      align-items: flex-start;
      gap: clamp(6px, 0.3vw, 40px);
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #aaa;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ccc1;
      transform: scaleX(0);
      z-index: 1;
      transition: 0.2s ease-in-out;
      transform-origin: left;
    }
    .date {
      display: flex;
      align-items: center;
      padding-left: f.clamp_prf(40px, 40px);
      font-family: v.$josefin;
      font-size: f.clamp_prf(16px, 22px);
      white-space: nowrap;
      letter-spacing: 0.05em;
      margin-top: 0.3em;
      @include m.sp {
        padding: 0;
        font-size: 14px;
      }
    }
    .title {
      padding-right: f.clamp_prf(30px, 60px);
      display: flex;
      justify-content: space-between;
      gap: f.clamp_prf(30px, 50px);
      width: 100%;
      font-weight: v.$fw_medium_500;
      font-size: f.clamp_prf(14px, 16px);
      @include m.sp {
        padding: 0;
      }
    }
    .link_name {
      text-align: justify;
      font-size: f.clamp_prf(16px, 22px);
      line-height: 181.818%;
      letter-spacing: f.px2em(22, 0.66);
      @include m.line_clamp(2);
      flex: 1;
      @include m.sp {
        padding: 0;
        font-size: 14px;
        line-height: 150%;
      }
    }
    .arrow {
      width: f.clamp_prf(18px, 26px);
      height: auto;
      z-index: 1;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      flex-shrink: 1;
      img {
        width: 100%;
        height: auto;
        transition: 0.2s;
        transform: translateX(26px);
        transform-origin: left top;
        opacity: 0;
      }
      @include m.sp {
        display: none;
      }
    }
    @include m.hover_device {
      &:hover {
        &:after {
          transform: scaleX(1);
        }
        .arrow {
          img {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
    }
  }
}
.c__pager {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: f.clamp_prf(16px, 20px);
  font-weight: v.$fw_exlight_200;
  font-family: v.$josefin;
  font-size: f.clamp_prf(16px, 24px);
  .disabled {
    display: none;
  }
  .elipsis {
    letter-spacing: 0.05em;
  }
  .underline {
    transition: 1.15s;
    padding-bottom: 10px;
    position: relative;
    &:before {
      transition: 0.5s;
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: max(100%, 10px);
      height: 1px;
      background-color: transparent;
      transition: all 0.3s;
      transform: scale(0, 1) translateX(-50%);
      transform-origin: center top;
      @include m.sp {
      }
    }
    &.active {
      pointer-events: none;
      &:before {
        transform: scale(1, 1) translateX(-50%);
        background-color: #fff;
      }
    }
    @include m.hover_device {
      &:hover {
        &:before {
          transform: scale(1, 1) translateX(-50%);
          background-color: #fff;
        }
      }
    }
  }
  .prev_link,
  .next_link {
    display: block;
    position: relative;
    width: 1.3em;
    height: 1.3em;
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-image: url(/assets/img/icon//wildcard_arrow_wh.svg);
      background-repeat: no-repeat;
      width: clamp(10px, 1.3em, 24px);
      height: clamp(10px, 1.3em, 24px);
      position: absolute;
      top: 50%;
      left: 0;
      transition: 0.15s;
    }
  }
  .prev_link {
    margin-right: clamp(16px, 0.98vw, 20px);
    &:before {
      left: 0;
      transform: translate(0, -53%) rotate(180deg);
    }
    @include m.hover_device {
      &:hover {
        &:before {
          transform: translate(0, -63%) scale(1.1) rotate(180deg);
        }
      }
    }
  }
  .next_link {
    margin-left: clamp(16px, 0.98vw, 20px);
    &:before {
      left: 0;
      transform: translate(0, -50%);
    }
    @include m.hover_device {
      &:hover {
        &:before {
          transform: translate(0, -50%) scale(1.1);
        }
      }
    }
  }
}
/*=======================================
  コンテンツエリア
=======================================*/
.c__article_wrapper {
  z-index: 5;
  position: relative;
  .article_thumbnail {
    position: relative;
    padding-top: v.$ratio_16_9;
    margin-top: f.clamp_prf(30px, 50px);
    img,
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .article_head_info {
    display: flex;
    align-items: baseline;
    gap: f.clamp_prf(16px, 30px);
    margin-top: f.clamp_prf(40px, 80px);
    font-size: f.clamp_prf(12px, 16px);
    font-weight: v.$fw_bold_700;
    letter-spacing: 0.12em;
    flex-wrap: wrap;
  }
  .article_category {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .article_title {
    font-size: f.clamp_prf(20px, 28px);
    font-weight: v.$fw_bold_700;
    margin-top: f.clamp_prf(16px, 20px);
    letter-spacing: 0.12em;
    line-height: 1.5;
  }
  .sub_images {
    // display: flex;
    display: grid;
    margin-top: f.clamp_prf(30px, 50px);
    gap: clamp(10px, 2.93vw, 20px);
    grid-auto-rows: minmax(40px, auto);
    grid-template-columns: repeat(auto-fill, minmax(min(38vw, 460px), 1fr));
    .sub_image {
      position: relative;
      width: 100%;
      padding-top: v.$ratio_16_9;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s ease-out;
      }
      &:hover {
        img {
          transform: scale(103%);
        }
      }
    }
  }
  .c__article_body {
    margin-top: f.clamp_prf(30px, 50px);
  }

  // SNSシェアボタンと前後記事ボタンの幅を連動させるための変数
  $article_prev_next_padding: clamp(20px, 3vw, 30px);
  $article_prev_next_arrow_size: clamp(40px, 6vw, 60px);

  .c__sns_share {
    margin: f.clamp_prf(80px, 160px) auto 0;
  }
  .article_prev_next {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: f.clamp_prf(60px, 100px);
    gap: f.clamp_prf(60px, 120px);
    font-size: f.clamp_prf(16px, 20px);
    letter-spacing: 0.05em;
    font-family: v.$josefin;
    @include m.sp {
      gap: 0;
    }
    .pager_link_prev,
    .pager_link_next {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        background-image: url(/assets/img/icon//wildcard_arrow_wh.svg);
        background-repeat: no-repeat;
        width: clamp(16px, 1.3em, 24px);
        height: clamp(16px, 1.3em, 24px);
        position: absolute;
        top: 50%;
        transition: 0.15s;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .pager_link_prev {
      padding-left: 2.7em;
      &:before {
        left: 0;
        transform: translate(-0%, -61%) rotate(180deg);
      }
      @include m.hover_device {
        &:hover {
          &:before {
            transform: translate(-20%, -61%) rotate(180deg);
          }
        }
      }
      @include m.sp {
        white-space: nowrap;
        transform: translateX(2em);
      }
    }
    .pager_link_next {
      padding-right: 2.7em;
      &:before {
        right: 0%;
        transform: translate(0, -61%);
      }
      @include m.hover_device {
        &:hover {
          &:before {
            transform: translate(20%, -61%);
          }
        }
      }
      @include m.sp {
        white-space: nowrap;
        transform: translateX(-2em);
      }
    }
    @include m.sp {
      margin-bottom: 80px;
    }
  }
  .c__button_underline_hover {
    @include m.sp {
      white-space: nowrap;
      transform: translate(0, 80px);
    }
  }
}
.c__article_body {
  font-family: v.$noto;
  font-size: f.clamp_prf(14px, 18px);
  font-weight: v.$fw_regular_400;
  color: v.$letter_primary;
  letter-spacing: 0.15em;
  h2 {
    font-size: f.clamp_prf(20px, 26px);
    font-weight: v.$fw_semibold_600;
    margin-top: f.clamp_prf(18px, 24px);
    line-height: 1.8;
  }
  h3 {
    font-size: f.clamp_prf(18px, 24px);
    font-weight: v.$fw_semibold_600;
    margin-top: f.clamp_prf(18px, 24px);
    line-height: 1.8;
  }
  h4 {
    font-size: f.clamp_prf(17px, 22px);
    font-weight: v.$fw_semibold_600;
    margin-top: f.clamp_prf(18px, 24px);
    line-height: 1.8;
  }
  h5 {
    font-size: f.clamp_prf(16px, 20px);
    font-weight: v.$fw_semibold_600;
    margin-top: f.clamp_prf(18px, 24px);
    line-height: 1.8;
  }
  h6 {
    font-size: f.clamp_prf(15px, 20px);
    font-weight: v.$fw_medium_500;
    margin-top: f.clamp_prf(18px, 24px);
    line-height: 1.8;
  }
  p {
    margin-top: f.clamp_prf(18px, 24px);
    line-height: 1.8;
    text-align: justify;
    strong {
      font-weight: v.$fw_semibold_600;
    }
    em {
      font-style: italic;
    }
    u {
      text-decoration: underline;
    }
    s {
      text-decoration: line-through;
    }
    a {
      text-decoration: underline;
      color: v.$letter_primary;
      transition: 0.15s;
      &:hover {
        opacity: 0.7;
      }
    }
    img {
      width: 100%;
    }
  }
  ul {
    margin-top: f.clamp_prf(18px, 24px);
    list-style-type: disc;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 14px;
      line-height: 1.5;
    }
  }
  ol {
    margin-top: f.clamp_prf(18px, 24px);
    list-style-type: decimal;
    padding: 14px 14px 14px 34px;
    li {
      font-size: 14px;
      line-height: 1.5;
    }
  }
  figcaption {
    font-size: 14px;
    line-height: 1.8;
  }
  .wp-block-embed-youtube .wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  iframe {
    width: 100%;
    height: clamp(200px, 49.5vw, 788px);
    @include m.sp {
      height: clamp(150px, 48vw, 400px);
    }
  }
  .article_thumbnail {
    img,
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
/*=======================================
  フォーム系
=======================================*/
@mixin form_base($height, $font_size: 16px) {
  width: 100%;
  height: $height;
  font-family: v.$noto; //明示
  font-size: $font_size;
  padding: 16px 20px;
  transition: 0.2s ease;
  color: v.$letter_primary;
  -webkit-appearance: none; //safariの角丸対策
  border-radius: 0px; //safariの角丸対策
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  outline: 0;
  background-color: rgba(#fff, 0.03);
  line-height: 1.5;
  &:focus {
    border: none;
    border-bottom: 1px solid #aaa;
    background-color: rgba(#fff, 0.1);
  }
  &::placeholder {
    color: rgba(#fff, 0.5);
    font-family: v.$noto; //明示
    font-size: $font_size;
  }
}
.c__contact_label {
  font-size: 16px;
  font-weight: v.$fw_bold_700;
  color: v.$letter_primary;
  letter-spacing: 0.06em;
  cursor: text;
}
.c__contact_textbox {
  @include form_base(60px, 16px);
  @include m.sp {
    height: 48px;
    padding: 20px 18px;
  }
}
.c__contact_textarea {
  @include form_base(120px);
  max-height: 480px;
  resize: none;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border: none;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
  }
}
.c__contact_radio,
.c__contact_checkbox {
  display: none;
  + label {
    position: relative;
    padding-left: f.clamp_prf(26px, 36px);
    cursor: pointer;
  }
  + label:before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-0%, -44%);
    content: "";
    border: 1px solid #aaa;
    width: 20px;
    height: 20px;
    opacity: 1;
    transition: 0.3s;
  }
  &:checked + label:before {
    background-color: v.$red_light;
  }
  @include m.hover_device {
    &:hover {
      + label:before {
        background-color: rgba(255, 25, 25, 0.2);
      }
      &:checked + label:before {
        background-color: v.$red_light;
      }
    }
  }
}

.c__contact_select {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  &:after {
    // 三角
    position: absolute;
    right: 15px;
    width: 10px;
    height: 7px;
    background-color: #fff;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    content: "";
    pointer-events: none;
  }
  select {
    appearance: none; //各ブラウザのデフォルトをリセット
    width: 100%;
    @include form_base(60px, 16px);
    cursor: pointer;
    color: v.$letter_primary;
    -webkit-appearance: none; //safariの角丸対策
    border-radius: 0px; //safariの角丸対策
    border: none;
    outline: 0;
  }
  option {
    color: v.$letter_primary;
    font-family: v.$noto; //明示
    font-size: 16px;
    background: #333;
  }
}
.required::after {
  content: "*";
  color: v.$red_extralight;
  font-size: 18px;
  padding-left: 5px;
}
/*==============================
  装飾系
==============================*/
.c__external_link {
  @include m.external_link_icon;
}

/*=======================================
  アニメーション系
=======================================*/
.c__js_fade {
  transform: translate(0, 40px);
  opacity: 0;
  transition: 0.3s ease;
  &.active {
    transform: translate(0, 0) scale(100%);
    opacity: 1;
  }
}
.c__js_fade_repeat {
  transform: translate(0, 40px);
  opacity: 0;
  transition: 0.3s ease;
  &.active {
    transform: translate(0, 0) scale(100%);
    opacity: 1;
  }
  @include m.tran_delay(0.1);
}

// ディレイを付けたい場合
.c__js_fade_delay {
  .js__delay_child {
    transform: translate(0, 30px);
    opacity: 0;
    transition: 0.4s ease-out;
  }
  &.active {
    .js__delay_child {
      transform: translate(0, 0) scale(100%);
      opacity: 1;
      @include m.tran_delay(0.2);
    }
  }
}

.c__js_blur {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  filter: blur(10px);
  &.active {
    opacity: 1;
    filter: blur(0px);
  }
}
.js__transition {
  .js__transition_child {
    // .js__transition_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include m.tran_delay(0.2);
  }
}
.js__animation {
  .js__animation_child {
    // .js__animation_child .delay3 のようにクラスを指定すると0.2 * 3 = 0.6秒遅延
    @include m.anim_delay(0.2);
  }
}
.js__parallax_container {
  transition: 0.25s cubic-bezier(0.08, 0.93, 0.42, 0.96);
}

.js__load_required {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s ease;
  transform: translateY(5px);
  transition: 0.1s;
  &.loaded {
    opacity: 1;
    pointer-events: auto;
    height: auto;
    transform: translateY(0);
    filter: blur(0px);
    transition: 0.15s;
  }
}
