@use "../foundation/variables" as v;
@use "../foundation/functions" as f;
@use "../foundation/mixin" as m;

/*=======================================
  ヘッダー
=======================================*/
#header {
  width: 100%;
  font-family: v.$josefin;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  #header_pc {
    height: v.$header_height;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include m.tab {
      display: none;
    }
    .header_l {
      .logo {
        display: block;
        img {
          width: f.clamp_prf(180px, 240px);
        }
      }
    }
    .header_r {
      display: flex;
      font-size: clamp(18px, 1.8vw, 20px);
      font-weight: v.$fw_semibold_600;
      letter-spacing: f.xd_letter_spacing(60);
      gap: clamp(26px, 3vw, 46px);
      a:not(.service_icon) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 8px;
        &:before {
          content: "";
          position: absolute;
          bottom: 2px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #fff;
          transform: scale(0, 1);
          transform-origin: right top;
          transition: transform 0.15s;
        }
        &.active {
          &:before {
            transform: scale(1, 1);
          }
        }
        @include m.hover_device {
          &:hover {
            &:before {
              transform: scale(1, 1);
              transform-origin: left top;
            }
          }
        }
      }
    }
  }
  #sp_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 20;
    pointer-events: auto;
    height: v.$header_height_sp;
    @include m.tab {
      display: block;
    }
    #sp_header_logo {
      display: block;
      width: clamp(140px, 30vw, 180px);
      position: absolute;
      top: v.$body_pd;
      left: v.$body_pd;
      transition: 0.2s;
      &.disabled {
        visibility: hidden;
        opacity: 0;
      }
      img {
        width: 100%;
      }
    }
    #header_toggle {
      position: absolute;
      top: 0;
      right: 0;
      width: 66px;
      height: 66px;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        width: 20px;
        height: 1px;
        background-color: #fff;
        transition: 0.3s ease-in-out;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
      }
    }
    $header_toggle_top: calc(v.$body_pd + 6px);
    $header_toggle_top_offset: 6px;
    $header_toggle_right: v.$body_pd;
    #header_toggle span:nth-child(1) {
      top: $header_toggle_top;
      right: $header_toggle_right;
    }
    #header_toggle span:nth-child(2) {
      top: calc($header_toggle_top + $header_toggle_top_offset);
      right: $header_toggle_right;
    }
    #header_toggle span:nth-child(3) {
      top: calc($header_toggle_top + ($header_toggle_top_offset * 2));
      right: $header_toggle_right;
    }
    #header_toggle.active span:nth-child(1) {
      transform: translate(0, 7px) rotate(225deg);
    }
    #header_toggle.active span:nth-child(2) {
      transform: translate(50px, 0) scale(0);
    }
    #header_toggle.active span:nth-child(3) {
      transform: translate(0, -5px) rotate(-225deg);
    }
  }
}
#sp_menu {
  height: calc(f.svh(100));
  width: 100%;
  transition: all 0.3s ease-in-out;
  background: v.$bg_primary;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 15;
  &.active {
    @include m.tab {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }
  .menu_inner {
    visibility: inherit;
    opacity: inherit;
    width: clamp(216px, 100%, 700px);
    padding: 0 clamp(30px, 10vw, 70px);
    margin: calc(v.$header_height_sp + clamp(0px, 4vh, 60px)) auto 0;
    display: flex;
    flex-direction: column;
    gap: clamp(18px, 5vh, 40px);
  }
  .sp_menu_logo {
    display: block;
    margin-bottom: clamp(10px, 3vmin, 60px);
    width: clamp(200px, 56vmin, 300px);
    height: auto;
  }
  .link_name {
    font-family: v.$josefin;
    font-weight: v.$fw_semibold_600;
    font-size: clamp(16px, 5vmin, 24px);
    line-height: 1;
  }
  .c__sns_service_icon {
    gap: clamp(20px, 7vmin, 40px);
    .yt {
      width: clamp(28px, 7vmin, 40px);
    }
    .tw {
      width: clamp(25px, 7vmin, 37px);
    }
    .tk {
      width: clamp(22px, 7vmin, 35px);
      margin-left: clamp(-10px, -1vmin, -6px);
    }
  }
}

/*=======================================
  ホーム
=======================================*/
#p__home {
  font-family: v.$noto;
  width: 100%;
  max-width: 100%;
  #first_view {
    position: relative;
    width: 100%;
    height: f.static_svh(100);
    z-index: 1;
    opacity: 1;
    transition: opacity 0.5s;
    &.disabled {
      opacity: 0;
      visibility: hidden;
    }
    #fv_movie_wrapper {
      position: fixed;
      width: 100%;
      height: 100%;
      @include m.dot_filter;
      #fv_img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 0.5s;
        opacity: 1;
        &.disabled {
          opacity: 0;
        }
      }
      #fv_video {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 0.5s;
        z-index: 0;
        opacity: 0;
        &.active {
          opacity: 1;
        }
      }
      #fv_play_button {
        border: solid f.clamp_prf(2px, 4px) #fff;
        border-radius: 100vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: f.clamp_prf(60px, 140px);
        height: f.clamp_prf(60px, 140px);
        z-index: 1;
        mix-blend-mode: plus-lighter;
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
        &.active {
          opacity: 0.5;
          cursor: pointer;
          visibility: visible;
        }
        @include m.hover_device {
          &:hover {
            opacity: 1;
          }
        }
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-31%, -50%);
          transition: 0.2s;
          background-image: url(/assets/img/icon/wildcard_arrow_wh.svg);
          background-repeat: no-repeat;
          width: 50%;
          height: 50%;
        }
      }
    }
    .company_name {
      position: absolute;
      bottom: f.clamp_auto_pc2sp(90px, 0.8);
      right: v.$body_pd;
      font-family: v.$josefin;
      font-style: italic;
      font-size: f.clamp_prf(26px, 30px);
      letter-spacing: f.xd_letter_spacing(60);
      white-space: nowrap;
    }
    .business_domain {
      position: absolute;
      bottom: f.clamp_auto_pc2sp(46px, 0.8);
      right: v.$body_pd;
      font-family: v.$josefin;
      font-style: italic;
      font-size: f.clamp_prf(16px, 24px);
      letter-spacing: f.xd_letter_spacing(60);
      white-space: nowrap;
    }
    .scrolldown {
      position: absolute;
      z-index: 2;
      font-family: v.$josefin;
      font-size: 16px;
      left: v.$body_pd;
      bottom: 230px;
      color: #fff;
      letter-spacing: 0.2em;
      writing-mode: vertical-lr;
      white-space: nowrap;
      &::before {
        content: "";
        position: absolute;
        top: calc(100% + 20px);
        left: 50%;
        width: 2px;
        height: 160px;
        background-color: #fff;
        opacity: 0.3;
      }
      &::after {
        content: "";
        position: absolute;
        top: calc(100% + 20px);
        left: 50%;
        width: 2px;
        height: 20px;
        border-radius: 4px;
        animation: scrolldown 4s ease infinite;
        background-color: #fff;
      }
      @keyframes scrolldown {
        0% {
          opacity: 0;
          transform: translateY(0px);
        }
        5% {
          opacity: 1;
        }
        95% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: translateY(clamp(100px, 26vh, 140px));
        }
      }
      @include m.sp {
        left: auto;
        right: clamp(26px, 2vw, 50px);
        bottom: clamp(254px, 55vh, 300px);
        &::before {
          height: clamp(120px, 30vh, 160px);
        }
      }
    }
  }
  #home_works {
    @include m.content_width;
    position: relative;
    z-index: 2; // fvより前にしないとpointer-eventsがfvに吸われる
    opacity: 0;
    transition: 0.3s;
    &.active {
      opacity: 1;
    }
    .c__articles_grid {
      margin-top: clamp(36px, 6.34vw, 130px);
    }
    .c__primary_button {
      margin-top: f.clamp_auto_pc2sp(94px);
    }
    .works_polygon_bg {
      position: absolute;
      z-index: 2;
      &.above {
        top: -39%;
        right: -25%;
        transform: rotate(-210deg);
        width: 70vw;
        mix-blend-mode: overlay;
        opacity: 0.5;
      }
      &.below {
        bottom: -40%;
        left: -35%;
        transform: rotate(-23deg);
        width: 70vw;
        mix-blend-mode: overlay;
        opacity: 0.5;
      }
    }
  }
  #home_showreel {
    margin-top: f.clamp_prf(100px, 220px);
    background-color: #8a8a8a10;
    z-index: 2; // fvより前にしないとpointer-eventsがfvに吸われる
    .home_showreel_inner {
      @include m.content_width;
      padding-top: f.clamp_prf(90px, 184px);
      padding-bottom: f.clamp_prf(90px, 140px);
      .c__head_primary {
        img {
          width: 160%;
          height: 120%;
          transform: translate(-57%, -47%);
        }
      }
      #showreel_movie_wrapper {
        width: 100%;
        height: clamp(200px, 40vh, 400px);
        position: relative;
        margin-top: 20px;
        @include m.dot_filter;
        pointer-events: none;
        .play_button {
          border: solid f.clamp_prf(2px, 4px) #fff;
          border-radius: 100vh;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: clamp(60px, 15vw, 140px);
          height: clamp(60px, 15vw, 140px);
          z-index: 2;
          mix-blend-mode: plus-lighter;
          transition: 0.2s;
          opacity: 0.5;
          cursor: pointer;
          pointer-events: auto;
          z-index: 1;
          @include m.hover_device {
            &:hover {
              opacity: 1;
            }
          }
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-31%, -50%);
            transition: 0.2s;
            background-image: url(/assets/img/icon/wildcard_arrow_wh.svg);
            background-repeat: no-repeat;
            width: 50%;
            height: 50%;
          }
        }
        @include m.hover_device {
          &:hover {
            &:before {
              transform: translate(-50%, -75%) rotate(-90deg);
            }
          }
        }
        #player_wrapper {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          transition: 1s;
          &.disabled {
            opacity: 0;
          }
          iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200%;
            max-width: v.$pc_min;
            height: 60vmax;
            object-fit: cover;
            pointer-events: none;
          }
        }
      }
      .years {
        margin-top: f.clamp_auto_pc2sp(50px);
        display: flex;
        gap: f.clamp_auto_pc2sp(40px);
        flex-wrap: wrap;
        .year {
          font-family: v.$josefin;
          font-size: f.clamp_auto_pc2sp(29px);
          letter-spacing: f.xd_letter_spacing(60);
          position: relative;
          transition: color 0.15s;
          &:before {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #fff;
            transform: scale(0, 1);
            transform-origin: right top;
            transition: transform 0.15s;
            z-index: -1;
          }
          &.active {
            color: v.$red_extralight;
            &:before {
              color: v.$red_extralight;
              transform: scale(1, 1);
              transform-origin: left top;
              background-color: v.$red_extralight;
            }
          }
          @include m.hover_device {
            &:hover {
              &:before {
                transform: scale(1, 1);
                transform-origin: left top;
              }
            }
          }
        }
      }
    }
  }
  #home_news {
    @include m.content_width;
    margin-top: f.clamp_prf(100px, 220px);
    width: 100%;
    z-index: 2; // fvより前にしないとpointer-eventsがfvに吸われる
    .heading {
      display: flex;
      align-items: baseline;
      gap: f.clamp_auto_pc2sp(56px, 0.4);
      font-size: clamp(26px, 6vw, 50px);
      .c__head_primary {
        font-size: inherit;
      }
      .link {
        .text {
          position: relative;
          width: 140%;
          font-family: v.$josefin;
          font-weight: v.$fw_semibold_600;
          font-size: 0.6em;
          transition: 0.15s;
          .wh,
          .rd {
            position: absolute;
            width: 0.8em;
            top: 50%;
            right: 0;
            transform: translate(0%, -64%);
            transition: 0.15s;
          }
          .rd {
            opacity: 0;
          }
        }
        @include m.hover_device {
          &:hover {
            .text {
              color: v.$red_extralight;
            }
            .wh {
              opacity: 0;
              transform: translate(20%, -64%);
            }
            .rd {
              opacity: 1;
              transform: translate(20%, -64%);
            }
          }
        }
      }
    }
    .news_list {
      margin-top: f.clamp_prf(20px, 30px);
      padding: f.clamp_prf(40px, 50px) f.clamp_prf(20px, 50px);
      background-color: #ffffff09;
      display: flex;
      flex-direction: column;
      gap: f.clamp_prf(26px, 32px);
      font-size: f.clamp_prf(14px, 16px);
      .anchor {
        display: flex;
        gap: f.clamp_prf(10px, 30px);
        letter-spacing: f.xd_letter_spacing(60);
        position: relative;
        width: fit-content;
        &:before {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #999;
          transform: scale(0, 1);
          transform-origin: right top;
          transition: transform 0.3s;
          z-index: -1;
        }
        @include m.hover_device {
          &:hover {
            &:before {
              transform: scale(1, 1);
              transform-origin: left top;
            }
          }
        }
      }
      .date {
        white-space: nowrap;
      }
      .title {
        @include m.line_clamp(1);
      }
      @include m.sp {
        gap: 40px;
        padding-bottom: 60px;
        .anchor {
          width: 100%;
          flex-direction: column;
          &:before {
            transform: scale(1, 1);
          }
        }
        .date {
          font-size: 0.8em;
        }
        .title {
          line-height: 1.4em;
          @include m.line_clamp(2);
        }
      }
    }
  }
  #home_contact {
    margin-top: f.clamp_prf(100px, 220px);
    position: relative;
    z-index: 2; // fvより前にしないとpointer-eventsがfvに吸われる
    .link {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.5;
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform 0.5s cubic-bezier(0.21, 0.73, 0.41, 0.92);
        z-index: 2;
        mix-blend-mode: overlay;
      }
      @include m.hover_device {
        &:hover {
          &:before {
            transform: scale(1, 1);
            transform-origin: left top;
          }
        }
      }
    }
    #contact_bg_wrapper {
      width: 100%;
      height: clamp(400px, 40vw, 690px);
      overflow: hidden;
      background: #490406;
      position: relative;
      z-index: 1;
      animation: contact_bg_color 5.5s ease infinite;
      animation-delay: 1.5s;
      @include m.sp {
        height: clamp(240px, 90vw, 510px);
      }
      .contact_message_frame_pc {
        width: 90%;
        max-width: 1640px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -54%);
        z-index: 4;
      }
      .contact_message_pc {
        width: 42%;
        max-width: 740px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-63%, -18%);
        z-index: 4;
      }
      .contact_message_frame_sp {
        width: 86%;
        max-width: 560px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
      }
      .contact_message_sp {
        width: 57%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
        z-index: 4;
      }
      .contact_decoration {
        width: 63%;
        max-width: 1130px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -115%);
        mix-blend-mode: soft-light;
        z-index: 3;
        @include m.sp {
          transform: translate(-51%, -260%);
        }
      }
      .contact_message_frame_pc,
      .contact_message_pc {
        @include m.sp {
          display: none;
        }
      }
      .contact_message_frame_sp,
      .contact_message_sp {
        display: none;
        @include m.sp {
          display: block;
        }
      }
      .contact_arrow {
        width: 6.5%;
        max-width: 116px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(270%, -20%);
        z-index: 5;
        opacity: 0;
        // jsのgeometryInitのtransitionDelayとtransitionDurationにいい感じに連動させる
        // animation: contact_arrow 5.5s ease infinite;
        animation-name: contact_arrow;
        animation-duration: 5.5s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-delay: 1.5s;
        @include m.sp {
          transform: translate(-50%, 220%);
          animation-name: contact_arrow_sp;
          // animation: contact_arrow_sp 5.5s ease infinite;
        }
        @keyframes contact_arrow {
          0% {
            opacity: 0;
            transform: translate(290%, -8%);
          }
          30% {
            opacity: 1;
            transform: translate(310%, -8%);
          }
          70% {
            opacity: 1;
            transform: translate(310%, -8%);
          }
          100% {
            opacity: 0;
            transform: translate(330%, -8%);
          }
        }
        @keyframes contact_arrow_sp {
          0% {
            opacity: 0;
            transform: translate(-100%, 220%);
          }
          20% {
            opacity: 1;
            transform: translate(-50%, 220%);
          }
          80% {
            opacity: 1;
            transform: translate(-50%, 220%);
          }
          100% {
            opacity: 0;
            transform: translate(0%, 220%);
          }
        }
      }

      // 背景の幾何学模様
      #geometryangle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        g {
          mix-blend-mode: color-burn;
        }
        polygon {
          stroke: none;
        }
        @include m.sp {
          g {
            mix-blend-mode: overlay;
          }
        }
      }
    }
  }
}

/*=======================================
  WORKS一覧
=======================================*/
#p__works_index {
  padding-top: calc(v.$header_height + 90px);
  @include m.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
  .c__head_secondary {
    margin-top: f.clamp_prf(66px, 140px);
    font-size: f.clamp_prf(20px, 40px);
  }
  .c__categories_list {
    margin-top: f.clamp_prf(20px, 30px);
  }
  .c__articles_grid {
    margin-top: f.clamp_prf(60px, 100px);
  }
  .c__pager {
    margin-top: f.clamp_prf(80px, 100px);
  }
  .c__button_underline {
    margin: 0 auto 40px;
    @include m.tab {
      margin-bottom: 100px;
    }
  }
  .article_notfound {
    margin: 80px auto 80px;
    text-align: center;
  }
}

/*=======================================
  WORKS詳細
=======================================*/
#p_works_show {
  @include m.content_width(v.$content_width_m);
  padding-top: calc(v.$header_height + 90px);
  @include m.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
}

/*=======================================
  ABOUT
=======================================*/
#p__about {
  padding-top: calc(v.$header_height + 90px);
  @include m.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
  .about_inner {
    max-width: v.$content_width_s;
    margin: 0 auto;
  }
  .concept {
    margin-top: f.clamp_prf(100px, 240px);
    position: relative;
    .concept_head {
      font-family: v.$josefin;
      font-size: clamp(38px, 5.12vw, 64px);
      letter-spacing: 0.05em;
      position: absolute;
      top: -5%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      font-weight: v.$fw_bold_700;
      @include m.sp {
        left: 0%;
        transform: translateX(0%);
        font-size: clamp(16px, 6.3vw, 48px);
      }
    }
    .concept_flame {
      width: 100%;
      height: auto;
      z-index: 1;
      @include m.sp {
        display: none;
      }
    }
    .concept_flame_sp {
      width: 100%;
      height: auto;
      z-index: 1;
      display: none;
      @include m.sp {
        display: block;
      }
    }
    .concept_bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // mix-blend-mode: color-burn;
      z-index: -1;
      @include m.sp {
        filter: brightness(1.5);
      }
    }
    .concept_bg_gradation {
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, #ffffff00 0%,#ffffff00 50%, #ffffffff 60%, #ffffff00 70%);
      background-size: 300% 100%;
      animation: bggradient 4s ease-in infinite;
      z-index: 0;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      // mix-blend-mode: color-dodge;
      mix-blend-mode: soft-light;
      @keyframes bggradient {
        0% {
          // background-position: 0% 0%;
          background-position: 0% 0%;
          opacity: 0;
        }
        25% {
          opacity: 1;
          // background-position: 0% 0%;
        }
        40% {
          opacity: 0;
          background-position: 100% 0%;
        }
        // 75% {
        //   opacity: 0;
        //   background-position: 100% 0%;
        // }
        100% {
          opacity: 0;
          background-position: 0% 0%;
        }
      }
    }
  }
  .message {
    margin-top: f.clamp_prf(100px, 186px);
    position: relative;
    font-size: f.clamp_prf(16px, 26px);
    .symbol {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.1;
      filter: blur(3px);
      z-index: -1;
      width: f.clamp_prf(150px, 200px);
      padding-bottom: 5em;
      @include m.tab {
        width: 140px;
      }
    }
    .message_text {
      font-size: inherit;
      line-height: f.xd_line_height(26, 70);
      letter-spacing: f.xd_letter_spacing(100);
      text-align: center;
      font-weight: v.$fw_medium_500;
      font-family: v.$noto_yakuhan;
      span {
        font-family: v.$noto_yakuhan;
      }
      @include m.sp {
        text-align: justify;
        .u__sp_only {
          display: inline-block;
        }
      }
    }
  }
  .serveices {
    margin-top: f.clamp_prf(100px, 220px);
    padding: f.clamp_prf(100px, 180px) 0;
    background-color: #8a8a8a12;
    @include m.spread_over_parent;
    .head_wrapper {
      @include m.content_width(v.$content_width_s);
    }
    .serveices_text {
      @include m.content_width(v.$content_width_s);
      margin: f.clamp_prf(40px, 60px) auto 0;
      font-size: f.clamp_prf(16px, 22px);
      line-height: f.xd_line_height(22, 42);
      letter-spacing: f.xd_letter_spacing(60);
    }
    .services_icon_wrapper {
      @include m.content_width(v.$content_width_s);
      margin: f.clamp_prf(80px, 80px) auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: f.clamp_prf(80px, 150px);
      @include m.sp {
        flex-direction: column;
      }
      .services_icon {
        .img_wrapper {
          position: relative;
          width: f.clamp_prf(140px, 200px);
          height: f.clamp_prf(120px, 200px);
        }
        img {
          width: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .services_icon_text {
        margin-top: f.clamp_prf(10px, 20px);
        font-size: f.clamp_prf(15px, 22px);
        letter-spacing: f.xd_letter_spacing(100);
        text-align: center;
        font-weight: v.$fw_bold_700;
      }
    }
    .serveces_description_wrapper {
      @include m.content_width(v.$content_width_s);
      margin: f.clamp_prf(80px, 80px) auto 0;
      display: flex;
      flex-direction: column;
      gap: f.clamp_prf(30px, 50px);
      .serveces_description_head {
        font-weight: v.$fw_bold_700;
        font-size: f.clamp_prf(18px, 22px);
        letter-spacing: f.xd_letter_spacing(100);
      }
      .serveces_description {
        margin-top: f.clamp_prf(20px, 20px);
        font-size: f.clamp_prf(12px, 16px);
        line-height: f.xd_line_height(16, 42);
        letter-spacing: f.xd_letter_spacing(60);
      }
    }
  }
  .profile {
    margin-top: f.clamp_prf(100px, 200px);
    .profile_wrapper {
      margin-top: f.clamp_prf(70px, 120px);
      display: flex;
      gap: f.clamp_prf(40px, 60px);
      .profile_l_pc {
        display: flex;
        flex-direction: column;
        gap: f.clamp_prf(20px, 40px);
        width: f.clamp_prf(260px, 294px);
        @include m.sp {
          width: 100%;
          max-width: 320px;
        }
        .profile_image {
          width: 100%;
          height: auto;
        }
      }
      .company_summary_wrapper {
        display: flex;
        gap: f.clamp_prf(20px, 30px);
        .company_summary_head,
        .company_summary_text {
          font-size: f.clamp_prf(12px, 14px);
          line-height: f.xd_line_height(14, 34);
          letter-spacing: f.xd_letter_spacing(60);
        }
      }
      .profile_r_pc {
        flex: 1;
        .ceo_name {
          font-size: f.clamp_prf(26px, 30px);
          white-space: nowrap;
          letter-spacing: f.xd_letter_spacing(60);
          font-weight: v.$fw_bold_700;
        }
        .ceo_text {
          margin-top: f.clamp_prf(20px, 30px);
          font-size: f.clamp_prf(14px, 20px);
          line-height: f.xd_line_height(20, 36);
          letter-spacing: f.xd_letter_spacing(60);
          max-width: 600px;
        }
        .c__sns_service_icon {
          margin-top: f.clamp_prf(30px, 44px);
        }
        .company_summary_wrapper {
          display: none;
        }
      }
      @include m.tab {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .profile_l_pc .company_summary_wrapper {
          display: none;
        }
        .profile_r_pc .company_summary_wrapper {
          display: flex;
          margin-top: f.clamp_prf(60px, 80px);
        }
      }
    }
  }
  .next_info_wrapper {
    display: flex;
    justify-content: space-between;
    gap: f.clamp_prf(50px, 70px);
    margin-top: f.clamp_prf(180px, 290px);
    height: f.clamp_prf(110px, 326px);
    @include m.sp {
      flex-direction: column;
      height: auto;
    }
    .next {
      width: clamp(300px, 26vw, 460px);
      display: flex;
      flex-direction: column;
      @include m.sp {
        flex-direction: column;
        width: 100%;
        gap: f.clamp_prf(10px, 30px);
      }
      @include m.hover_device {
        &:hover {
          .next_head {
            &:before {
              transform: translateY(-71%) translateX(15%);
            }
          }
        }
      }
    }
    .next_head {
      position: relative;
      font-size: f.clamp_prf(80px, 140px);
      letter-spacing: f.xd_letter_spacing(60);
      white-space: nowrap;
      font-weight: v.$fw_bold_700;
      font-family: v.$josefin;
      padding-right: 1em;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: -5%;
        transform: translateY(-71%);
        transition: 0.2s;
        background-image: url(/assets/img/icon/wildcard_arrow_wh.svg);
        background-repeat: no-repeat;
        width: 13%;
        height: 45%;
      }
      @include m.sp {
        font-size: f.clamp_prf(60px, 80px);
        &:before {
          right: auto;
          left: 3.4em;
        }
      }
    }
    .next_text {
      font-size: f.clamp_prf(20px, 24px);
      font-weight: v.$fw_bold_700;
      letter-spacing: 0.05em;
      margin-left: 0.3em;
      @include m.sp {
        font-size: clamp(14px, 3vw, 20px);
      }
    }
    .movie_wrapper {
      flex: 1;
      position: relative;
      display: block;
      @include m.dot_filter;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 1;
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform 0.5s cubic-bezier(0.21, 0.73, 0.41, 0.92);
        z-index: 2;
        mix-blend-mode: overlay;
      }
      &:hover {
        &:before {
          transform: scale(1, 1);
          transform-origin: left top;
        }
      }
      .home_showreel_videos {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @include m.sp {
        flex: auto;
        height: f.clamp_prf(110px, 326px);
      }
    }
  }
}

/*=======================================
  NEWS一覧
=======================================*/
#p_news_index {
  padding-top: calc(v.$header_height + 90px);
  @include m.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
  .c___news_list {
    @include m.content_width(v.$content_width_s);
    margin-top: f.clamp_prf(40px, 240px);
    @include m.sp {
      padding: 0;
    }
  }
  .c__pager {
    margin-top: f.clamp_prf(80px, 100px);
  }
  .article_notfound {
    margin: 80px auto 80px;
    text-align: center;
  }
}

/*=======================================
  NEWS詳細
=======================================*/
#p_news_show {
  @include m.content_width(v.$content_width_m);
  padding-top: calc(v.$header_height + 90px);
  @include m.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
}

/*=======================================
  お問い合わせ
=======================================*/
#p__contact {
  padding-top: calc(v.$header_height + 90px);
  @include m.tab {
    padding-top: calc(v.$header_height_sp + 40px);
  }
  .contact_inner {
    max-width: v.$content_width_s;
    margin: 0 auto;
  }
  .contact_description {
    margin-top: f.clamp_prf(66px, 76px);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  form {
    margin-top: f.clamp_prf(66px, 76px);
  }
  .input_wrapper {
    margin-bottom: f.clamp_prf(54px, 70px);
  }
  #radio_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 40px 0 0 20px;
    @include m.sp {
      margin: 40px 0 0 0;
    }
  }
  .c__contact_label {
    display: block;
    margin-bottom: f.clamp_prf(10px, 24px);
  }
  .textarea_wrapper {
    margin-bottom: 24px;
    overflow: hidden;
  }
  .agreement_wrapper {
    margin-top: f.clamp_prf(60px, 60px);
    label {
      display: inline-block;
      margin-top: f.clamp_prf(20px, 20px);
      font-size: f.clamp_prf(16px, 18px);
    }
    .agreement_notice {
      font-size: f.clamp_prf(16px, 18px);
      text-align: left;
      line-height: 1.5;
      letter-spacing: 0.05em;
    }
    a {
      text-decoration: underline;
      line-height: 1;
      &:hover {
        transition: 0.3s;
        opacity: 0.8;
      }
    }
    .c__contact_label {
      margin: 50px 0 20px;
    }
  }
  #js_err_existence {
    font-size: f.clamp_prf(16px, 18px);
    font-weight: v.$fw_medium_500;
    display: flex;
    gap: 10px;
    flex-direction: column;
    .u__error_list {
      display: block;
      font-size: f.clamp_prf(14px, 16px);
      &:last-child {
        margin-bottom: f.clamp_prf(40px, 80px);
      }
    }
  }
  .c__error_message {
    margin-top: 20px;
  }
  .c__secondary_button {
    margin: f.clamp_prf(60px, 140px) auto 0;
  }
}
#p__contact_submitted {
  padding-top: calc(v.$header_height);
  @include m.tab {
    padding-top: calc(v.$header_height_sp);
  }
  .c__head_primary {
    margin: 76px 0 90px;
    @include m.sp {
      margin: 66px 0 40px;
    }
  }
  .submitted_description {
    margin-bottom: 70px;
    text-align: center;
    @include m.sp {
      text-align: left;
      margin-bottom: 44px;
    }
  }
  .c__secondary_button {
    margin: 0 auto 20px;
  }
}

/*=======================================
  Terms
=======================================*/
#p__terms {
  @include m.content_width;
  padding-top: calc(v.$header_height + 90px);
  .terms_inner {
    max-width: v.$content_width_s;
    margin: 0 auto;
    padding-top: f.clamp_auto_pc2sp(140px);
    display: flex;
    flex-direction: column;
    gap: f.clamp_auto_pc2sp(100px);
  }
  .c__head_secondary {
    font-size: clamp(22px, 6vw, 40px);
  }
  .section_header {
    font-size: 18px;
    font-weight: v.$fw_medium_500;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  .article_block {
    padding-top: f.clamp_auto_pc2sp(50px);
  }
  ul {
    list-style-type: disc;
    padding: 14px 0 14px 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    li {
      font-size: 15px;
      line-height: 1.5em;
    }
  }
  .txt {
    font-size: 14px;
    text-align: left;
    line-height: 1.8;
    letter-spacing: 0.08em;
    a {
      text-decoration: underline;
    }
  }
}

/*=======================================
  404
=======================================*/
#p__notfound {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include m.content_width;
  padding-top: calc(v.$header_height + 90px);

  .c__head_primary {
    font-size: f.clamp_prf(36px, 120px);
    line-height: 1.3;
  }
  .c__common_text {
    margin-top: f.clamp_auto_pc2sp(80px);
  }
  .c__secondary_button {
    margin-top: f.clamp_auto_pc2sp(80px);
  }
}

/*=======================================
  フッター
=======================================*/
#footer {
  padding: clamp(80px, 30vw, 280px) v.$body_pd v.$body_pd;
  width: 100%;
  font-family: v.$josefin;
  position: relative;
  overflow: hidden;
  @include m.sp {
    padding-right: 100px;
  }
  .footer_sns {
    display: none;
    @include m.sp {
      display: flex;
    }
  }
  .footer_nav {
    display: flex;
    margin-top: 100px;
    font-size: clamp(16px, 1.8vw, 20px);
    font-weight: v.$fw_regular_400;
    letter-spacing: f.xd_letter_spacing(60);
    gap: clamp(36px, 3vw, 46px);
    @include m.sp {
      margin-top: 48px;
      flex-direction: column;
      gap: 32px;
    }
    a {
      display: flex;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #999;
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform 0.15s;
      }
      @include m.hover_device {
        &:hover {
          &:before {
            transform: scale(1, 1);
            transform-origin: left top;
          }
        }
      }
    }
    // youtubeアイコン系の設定
    .service_icon {
      position: relative;
      display: block;
      margin-bottom: 2px;
      @include m.sp {
        display: none;
      }
      &.yt {
        width: f.clamp_prf(33px, 40px);
      }
      img {
        display: block;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: 0.15s;
      }
      .white {
        visibility: visible;
        opacity: 1;
      }
      .color {
        z-index: 10;
        visibility: hidden;
        opacity: 0;
      }
      @include m.hover_device {
        &.yt:hover,
        &.tw:hover,
        &.tk:hover {
          .color {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .copyright {
    font-size: f.clamp_prf(12px, 14px);
    margin-top: 48px;
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
  }
  .footer_background {
    position: absolute;
    transform: rotate(206deg);
    width: 60vw;
    opacity: 0.05;
    z-index: -1;
    bottom: -20vw;
    left: -22vw;
    pointer-events: none;
    @include m.tab {
      bottom: -22vw;
      left: -16vw;
      transform: rotate(187deg);
      width: 70vw;
      opacity: 0.1;
    }
    @include m.sp {
      bottom: -30vw;
      left: -30vw;
      width: 100vw;
      transform: rotate(189deg);
    }
  }
}
/*=======================================
  ページトップボタン
=======================================*/
#pagetop_button {
  position: fixed;
  bottom: 10px;
  right: 26px;
  opacity: 0;
  z-index: 7;
  transition: 0.2s;
  pointer-events: none;
  &.active {
    bottom: 26px;
    opacity: 1;
    pointer-events: auto;
  }
  button {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    border: solid 2px #fff;
    border-radius: 100vh;
    transition: 0.2s;
    @include m.sp {
      width: 50px;
      height: 50px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -65%) rotate(-90deg);
      width: clamp(10px, 1.3vw, 72px);
      height: clamp(10px, 1.3vw, 72px);
      transition: 0.2s;
      background-image: url(/assets/img/icon/wildcard_arrow_wh.svg);
      background-repeat: no-repeat;
      width: 45%;
      height: 45%;
    }
    @include m.hover_device {
      &:hover {
        &:before {
          transform: translate(-50%, -75%) rotate(-90deg);
        }
      }
    }
  }
}
/*=======================================
  背景系
=======================================*/
.background {
  position: relative;
  min-height: f.svh(100);
  &:before {
    transition: 0.7s;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: v.$bg_grad;
    z-index: -50;
  }
  &.active {
    &:before {
      opacity: 1;
      transition: 1s;
    }
  }
}
/*=======================================
  モーダル系設定まとめ
=======================================*/
.youtube_modal_wrapper {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: relative;
  iframe {
    width: clamp(200px, 80vw, 900px);
    height: auto;
    max-height: 50vh;
    aspect-ratio: 16/9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  button {
    position: absolute;
    top: 80vh;
    left: 50%;
    transform: translateX(-50%);
    font-size: f.clamp_prf(30px, 40px);
    font-weight: v.$fw_bold_700;
    font-family: v.$josefin;
  }
}

/*=======================================
  マウスストーカー
=======================================*/
#mouse_stalker {
  pointer-events: none;
  z-index: 9500;
  @include m.hover_device {
    &.loaded {
      opacity: 0.7;
    }
    position: fixed;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    background: v.$tw_blue;
    border-radius: 100vh;
    transform: translate(0, 0);
    transition: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);
    // ホバーした際の挙動
    &.stalker_cursor_pointer {
      top: -25px;
      left: -25px;
      width: 50px;
      height: 50px;
      opacity: 0.5;
    }
  }
  @include m.touch_device {
    display: none;
  }
}
.polygon_bg_header {
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: 0.3s;
  top: -18vw;
  right: -18vw;
  transform: rotate(32deg);
  width: 53vw;
  min-width: 600px;
  &.active {
    opacity: 0.1;
  }
  @include m.tab {
    top: -27vw;
    right: -25vw;
    transform: rotate(24deg);
    width: 71vw;
    &.active {
      opacity: 0.2;
    }
  }
  @include m.sp {
    width: 94vw;
    top: -34vw;
    right: -49vw;
    min-width: 310px;
    transform: rotate(24deg);
    max-width: none;
    &.active {
      opacity: 0.4;
    }
  }
}

// luminousのスタイルを上書き
.lum-lightbox {
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9) !important;
  @include m.tab {
    .lum-img {
      max-width: 90vw;
    }
  }
}
a.js__luminous {
  display: block;
}
